// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("/tmp/4cc007ce/www/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sections-contable-js": () => import("/tmp/4cc007ce/www/src/pages/sections/contable.js" /* webpackChunkName: "component---src-pages-sections-contable-js" */),
  "component---src-pages-sections-software-js": () => import("/tmp/4cc007ce/www/src/pages/sections/software.js" /* webpackChunkName: "component---src-pages-sections-software-js" */),
  "component---src-pages-services-contable-sirc-js": () => import("/tmp/4cc007ce/www/src/pages/services/contable/sirc.js" /* webpackChunkName: "component---src-pages-services-contable-sirc-js" */),
  "component---src-pages-services-contable-sueldos-js": () => import("/tmp/4cc007ce/www/src/pages/services/contable/sueldos.js" /* webpackChunkName: "component---src-pages-services-contable-sueldos-js" */)
}

